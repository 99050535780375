<template>
  <div>
    <p class="font-body-normal align-center">
      {{ tourPolicy.policy }}
    </p>
    <div class="mt-2 mb-2" v-if="shouldShowCancelCheckbox">
      <box color="rust">
        <k-checkbox v-model="cancelChecked">
          Cancel this tour
        </k-checkbox>
      </box>
      <div v-if="cancelChecked" class="cancel-tour-warning-text">
        Heads up! Checking the box above will cancel the tour for both you and the provider.
      </div>
    </div>
    <div
      class="tour-date"
      :class="cancelChecked && 'tour-date--disabled'"
    >
      <box class="time-zone-box"
           fa-icon="fa fa-exclamation-circle"
           :no-icon-bg="true" color="gold">
        {{ `All times in ${timeZoneAbbr}` }}
      </box>
      <flat-pickr v-model="selectedDate" :config="flatPickrConfig"
        @on-month-change="fetchAvailableSlots"
        @on-change="initTimeSelect"/>
      <k-form-field label="Choose time">
        <k-select
          v-model="selectedTime"
          :isDisabled="!enableSelect"
          :placeholder="enableSelect ? 'Choose time' : 'Select a date to see available times'"
          :options="filteredSlots">
        </k-select>
      </k-form-field>
    </div>
    <template v-if="loading">
      <loader :loading="loading" />
    </template>
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import Box from '@components/box.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';
import KFormField from '@components/forms/form_field.vue';
import KSelect from '@components/inputs/k-select.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import Loader from '@components/loader/loader.vue';
import timeZoneAbbr from '../../../common/mixins/timeZoneAbbr';

export default {
  components: {
    FlatPickr,
    KCheckbox,
    KFormField,
    KSelect,
    Loader,
    Box,
  },
  props: {
    tourData: {
      type: Object,
      default() {
        return {};
      },
    },
    centerId: {
      type: Number,
    },
    ageGroupPrograms: {
      type: Array,
      default() {
        return [];
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => { },
    },
  },
  mixins: [timeZoneAbbr],
  data() {
    return {
      selectedDate: undefined,
      selectedTime: undefined,
      filteredSlots: [],
      availableTours: [],
      tourPolicy: {},
      loading: false,
      cancelChecked: false,
    };
  },
  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  watch: {
    selectedTime(value) {
      let selectedTour = this.availableTours.filter((tour) => tour.start === value).shift();
      const instructions = selectedTour?.virtual ? this.tourPolicy.virtualInstructions : undefined;
      selectedTour = { ...selectedTour, virtualInstructions: instructions };
      this.onChange({ selectedTour });
    },
    selectedDate(newVal, oldVal) {
      if (oldVal && newVal !== oldVal && newVal !== this.bookedTour?.date) {
        this.selectedTime = undefined;
      }
    },
    cancelChecked(value) {
      this.onChange({ cancelChecked: value });
    },
    legupAgeGroupId() {
      this.tourSlots({
        centerId: this.centerId,
        ageGroupId: this.legupAgeGroupId,
      });
    },
  },
  async mounted() {
    this.loading = true;
    await this.centerTourPolicy();
    if (this.legupAgeGroupId) {
      await this.tourSlots({
        centerId: this.centerId,
        ageGroupId: this.legupAgeGroupId,
      });
    }
    this.initBookedTour(new Date().getMonth(), new Date().getFullYear(), true);
    this.loading = false;
  },
  methods: {
    async fetchAvailableSlots(selectedDates, dateStr, instance) {
      this.loading = true;
      this.selectedTime = undefined;
      this.filteredSlots = this.filteredSlots.splice(this.filteredSlots?.length);
      await this.tourSlots({
        centerId: this.centerId,
        ageGroupId: this.legupAgeGroupId,
        month: instance.currentMonth,
        year: instance.currentYear,
      });
      this.initBookedTour(instance.currentMonth, instance.currentYear);
      this.loading = false;
    },
    async centerTourPolicy() {
      await this.$store.dispatch('legup/centerTourPolicy', { centerId: this.centerId });
      this.tourPolicy = await this.$store.getters['legup/centerTourPolicy'];
    },
    async tourSlots(params) {
      await this.$store.dispatch('legup/centerTourSlots', { params });
      this.availableTours = await this.$store.getters['legup/centerTourSlots'];
    },
    dateFormat(date) {
      const year = date.toLocaleString('default', { year: 'numeric' });
      const month = date.toLocaleString('default', { month: '2-digit' });
      const day = date.toLocaleString('default', { day: '2-digit' });
      return `${year}-${month}-${day}`;
    },
    checkDates(date) {
      if (!this.selectedDate) {
        return false;
      }
      const thisDate = this.convertTimeZoneThenFormat(date);
      return this.selectedDate === thisDate;
    },
    initTimeSelect() {
      this.filteredSlots = this.availableTours.filter((tour) => this.checkDates(tour.start))
        .map((t) => {
          const date = new Date(t.start);
          const virtual = t.virtual ? '(virtual tour)' : '';
          const label = `${date.toLocaleTimeString('en-US', { timeZone: this.timezone, hour: '2-digit', minute: '2-digit' })} ${virtual}`;
          return { label, value: t.start };
        });
    },
    initBookedTour(month, year, mounted = false) {
      const isBookedDate = month === this.bookedMonth && year === this.bookedYear;
      if (isBookedDate || (mounted && this.tourData?.tourId)) {
        const { date, tour } = this.bookedTour;
        this.availableTours.push(tour);
        this.availableTours.sort((a, b) => a.start.localeCompare(b.start));
        this.selectedDate = date;
        this.selectedTime = tour.start;
      }
      this.initTimeSelect();
    },
    handleNext() {
      this.submitStep();
    },
    convertTimeZoneThenFormat(date) {
      let dateString = new Date(date);
      dateString = dateString.toLocaleString('en-US', { timeZone: this.timezone });
      return this.$moment(dateString).format('YYYY-MM-DD');
    },
  },
  computed: {
    flatPickrConfig() {
      const dateFormat = 'Y-m-d';
      const altFormat = 'm/d/Y';
      return {
        dateFormat,
        altFormat,
        altInput: true,
        altInputClass: 'k-input__input-field-hidden',
        enable: this.availableTours.map((d) => this.convertTimeZoneThenFormat(d.start)),
        inline: true,
        monthSelectorType: 'static',
      };
    },
    enableSelect() {
      return !!this.filteredSlots?.length;
    },
    legupAgeGroupId() {
      let legupAgeGroupId = this.$store.state.legup.dependentAgeGroup;
      if (this.tourData.offerDetails && Object.keys(this.tourData.offerDetails).length) {
        legupAgeGroupId = this.tourData.offerDetails.ageGroupId;
      }
      return legupAgeGroupId;
    },
    bookedMonth() {
      if (this.tourData?.tourId) {
        return new Date(this.bookedTour?.date).getMonth();
      }
      return undefined;
    },
    bookedYear() {
      if (this.tourData?.tourId) {
        return new Date(this.bookedTour?.date).getFullYear();
      }
      return undefined;
    },
    bookedTour() {
      if (this.tourData?.tourId) {
        const { startTime, virtual, endTime } = this.tourData;
        const diff = new Date(endTime) - new Date(startTime);
        const duration = Math.round(((diff % 86400000) % 3600000) / 60000);
        return {
          date: this.convertTimeZoneThenFormat(startTime),
          tour: { start: startTime, virtual, duration },
        };
      }
      return undefined;
    },
    shouldShowCancelCheckbox() {
      return !!this.bookedTour;
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-tour-warning-text {
  color: var(--rust-60);
  padding: calc(var(--grid-unit) * 2);
}

.align-center {
  text-align: center;
}

.time-zone-box {
  padding: 5px 10px;
  margin: 12px 0;
}

.tour-date {
  display: flex;
  flex-direction: column;
  align-items: center;

  &::v-deep .k-input__input-field-hidden {
    display: none;
  }

  &::v-deep .flatpickr-calendar {
    border: none;

    .flatpickr-months {
      background: var(--gray-0);

      span.cur-month {
        font-weight: 300;
      }

      .numInputWrapper {
        input.cur-year {
          pointer-events: none;
        }

        span.arrowUp,
        span.arrowDown {
          display: none;
        }
      }
    }
  }

  &::v-deep .flatpickr-calendar.animate.inline {
    margin: 0 auto;
  }

  &::v-deep .flatpickr-days {
    .dayContainer span.flatpickr-disabled {
      text-decoration: none;
    }
  }

  &::v-deep .kFormField {
    margin-left: -25px;

    .kFormField__label {
      width: unset;
    }

    .kFormField__inputWrapper {
      .k-select-wrapper {
        width: 250px;

        .k-select {
          background: white;
        }
      }
    }
  }
}

.tour-date--disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
